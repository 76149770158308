import {
  handleQueryResolve
} from '../utils'

export default function (patientID, drFirstName, drLastName, cpso) {
  if (patientID) {
    const attrs = [{
      param: 'PatientID',
      type: 'sql.Int',
      value: patientID, // to support like
    }]
    const where = ['Pat.ID = @PatientID']

    if (drFirstName) {
      attrs.push({
        param: 'drFirstName',
        type: 'sql.VarChar(50)',
        value: `%${drFirstName}%`, // to support like
      })

      where.push('doctor.FirstName LIKE @drFirstName')
    }

    if (drLastName) {
      attrs.push({
        param: 'drLastName',
        type: 'sql.VarChar(50)',
        value: `%${drLastName}%`, // to support like
      })

      where.push('doctor.LastName LIKE @drLastName')
    }

    if (cpso) {
      attrs.push({
        param: 'cpso',
        type: 'sql.VarChar(50)',
        value: `%${cpso}%`, // to support like
      })

      where.push('doctor.Licence1 LIKE @cpso')
    }

    return this.query(`
        SELECT
        doctor.ID,
        Pat.ID as PatientID,
        doctor.FirstName as DoctorFirstName,
        doctor.LastName as DoctorLastName,
        doctor.Licence1 as CPSO,
        DoctorFax.Phone as DoctorFax,
        DoctorPhone.Phone as DoctorPhone,
        DoctorAddress.Address1 as DoctorAddress,
        DoctorAddress.City as DoctorCity,
        DoctorAddress.Prov as DoctorProvince,
        DoctorAddress.Postal as DoctorPostal,
        doctor.Licence1 as DoctorSelectId,
        COUNT(doctor.ID) as RXperProvider
        FROM
        Pharmacy.dbo.Rx as rx
        INNER JOIN
        Pharmacy.dbo.Doc as doctor ON doctor.ID = rx.DocID
        INNER JOIN
        Pharmacy.dbo.DocAdd as DoctorAddress ON doctor.ID = DoctorAddress.DocID AND DoctorAddress.DefaultLocation = 1
        INNER JOIN
        Pharmacy.dbo.Pat ON rx.PatID = Pat.ID
        LEFT JOIN
        Pharmacy.dbo.DocPhone as DoctorFax ON doctor.ID = DoctorFax.DocID AND DoctorFax.Type = '2'
        LEFT JOIN
        Pharmacy.dbo.DocPhone as DoctorPhone ON doctor.ID = DoctorPhone.DocID AND DoctorPhone.Type = '1'
        WHERE
        rx.FillDate > DATEADD(year, -1, GetDate())
        AND
            ${where.join(' AND ')}
        GROUP BY
        doctor.ID,
        Pat.ID,
        doctor.FirstName,
        doctor.LastName,
        DoctorFax.Phone,
        DoctorPhone.Phone,
        DoctorAddress.Address1,
        DoctorAddress.City,
        DoctorAddress.Prov,
        DoctorAddress.Postal,
        doctor.Licence1
        ORDER BY RXperProvider DESC
        `, attrs).then(handleQueryResolve)
  }
}
